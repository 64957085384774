import React from 'react'

function Features() {
    return (
        <div className="bg-base-200" id="features">
            <div className="space-y-16 container xl:max-w-7xl mx-auto px-4 pt-8 pb-16 lg:px-8 lg:pt-16 lg:pb-32">

                <div className="text-center flex flex-col pb-10">
                    <div className="text-sm uppercase font-bold tracking-wider mb-1 text-primary">
                        Explore Our Features
                    </div>
                    <h2 className="text-3xl md:text-4xl font-medium mb-4 text-secondary">
                        Discover the Power of GeoProxy Service
                    </h2>
                    <h3 className="text-lg md:text-xl md:leading-relaxed font-medium text-base-content lg:w-2/3 mx-auto">
                    Level up your web scraping with Geoproxy's proxy API! 💻  HTTP, SOCKS4, SOCKS5 data at your fingertips.  😉 Stay anonymous, bypass restrictions.
                    </h3>
                </div>

                <div className="space-y-6">
                    <div className="flex flex-col md:flex-row md:gap-10 gap-6 justify-center relative">
                        <div className="flex-1 p-4 rounded-md shadow-md md:mx-0 border border-base-200 bg-base-300">
                            <div className="flex items-center space-x-3">
                                <div className="flex-none flex items-center justify-center">
                                    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                        className="hi-solid hi-check-circle inline-block w-5 h-5 text-secondary">
                                        <path fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <h4 className="font-medium">
                                    Comprehensive Proxy Information
                                </h4>
                            </div>
                        </div>
                        <div className="flex-1 p-4 rounded-md shadow-md md:mx-0 border border-base-200 bg-base-300">
                            <div className="flex items-center space-x-3">
                                <div className="flex-none flex items-center justify-center">
                                    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                        className="hi-solid hi-check-circle inline-block w-5 h-5 text-secondary">
                                        <path fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <h4 className="font-medium">
                                    Google passed & Elite Proxies
                                </h4>
                            </div>
                        </div>
                        <div className="flex-1 p-4 rounded-md shadow-md md:mx-0 border border-base-200 bg-base-300">
                            <div className="flex items-center space-x-3">
                                <div className="flex-none flex items-center justify-center">
                                    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                        className="hi-solid hi-check-circle inline-block w-5 h-5 text-secondary">
                                        <path fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <h4 className="font-medium">
                                    Geolocation Insights
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:gap-10 gap-6 justify-center relative">
                        <div className="flex-1 p-4 rounded-md shadow-md md:mx-0 border border-base-200 bg-base-300">
                            <div className="flex items-center space-x-3">
                                <div className="flex-none flex items-center justify-center">
                                    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                        className="hi-solid hi-check-circle inline-block w-5 h-5 text-secondary">
                                        <path fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <h4 className="font-medium">
                                    Developer-Friendly API
                                </h4>
                            </div>
                        </div>
                        <div className="flex-1 p-4 rounded-md shadow-md md:mx-0 border border-base-200 bg-base-300">
                            <div className="flex items-center space-x-3">
                                <div className="flex-none flex items-center justify-center">
                                    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                        className="hi-solid hi-check-circle inline-block w-5 h-5 text-secondary">
                                        <path fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <h4 className="font-medium">
                                    Regular Database Updates (30 mins)
                                </h4>
                            </div>
                        </div>
                        <div className="flex-1 p-4 rounded-md shadow-md md:mx-0 border border-base-200 bg-base-300">
                            <div className="flex items-center space-x-3">
                                <div className="flex-none flex items-center justify-center">
                                    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                        className="hi-solid hi-check-circle inline-block w-5 h-5 text-secondary">
                                        <path fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <h4 className="font-medium">
                                    HTTP, SOCKS4, SOCKS5
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Features