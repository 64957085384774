import React from 'react'
import Hero from '../components/Hero'
import Features from '../components/Features'
import Downloads from '../components/Downloads'

function Home() {
  return (
    <div>
        <Hero/>
        <Features/>
        <Downloads/>
    </div>
  )
}

export default Home